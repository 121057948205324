<template>
  <div>
    <!-- <v-row class="mb-2" v-if="account_type === 'manager'">
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(otherData.price ? otherData.price : 0) +
                  (otherData.currencySymbol ? otherData.currencySymbol : '')
                }}
              </h2>
              <span>الواردات الكلية</span>
            </div>

            <v-icon size="30" color="primary" class="rounded-0">
              {{ icons.mdiCashMultiple }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(
                    (otherData.price ? otherData.price : 0) -
                      (otherData.discount_amount ? otherData.discount_amount : 0),
                  ) + (otherData.currencySymbol ? otherData.currencySymbol : '')
                }}
              </h2>
              <span>الواردات بعد التخفيض</span>
            </div>

            <v-icon size="30" color="secondary" class="rounded-0"> fa-coins </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(otherData.discount_amount ? otherData.discount_amount : 0) +
                  (otherData.currencySymbol ? otherData.currencySymbol : '')
                }}
              </h2>
              <span>التخفيض</span>
            </div>

            <v-icon size="30" color="warning" class="rounded-0"> fa-cash-register </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(otherData.payment_amount ? otherData.payment_amount : 0) +
                  (otherData.currencySymbol ? otherData.currencySymbol : '')
                }}
              </h2>
              <span>المبالغ المستحصلة</span>
            </div>

            <v-icon size="40" color="error" class="rounded-0">
              {{ icons.mdiCash }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mb-2" v-if="account_type === 'manager'">
      <v-col cols="12" sm="6" md="4">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{
                  numberWithComma(otherData.remaining ? otherData.remaining : 0) +
                  (otherData.currencySymbol ? otherData.currencySymbol : '')
                }}
              </h2>
              <span>المبالغ المتبقية</span>
            </div>

            <v-icon size="40" color="warning" class="rounded-0">
              {{ icons.mdiCashMultiple }}
            </v-icon>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الفواتير</h1>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <!-- <v-btn
              tile
              color="primary"
              class="ml-2 mb-4 mb-md-0 mb-sm-0"
              :block="isScreenXs"
              @click="addDialog.open = true"
            >
              اضافة فاتورة <v-icon right> fa-plus </v-icon>
            </v-btn> -->
            <v-btn
              tile
              color="success"
              :loading="xlsxData.downloadLoading"
              :block="isScreenXs"
              @click="getDataAxiosAll"
            >
              تحميل اكسل <v-icon right> fa-download </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.start_date"
                dense
                label="من"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.start_date" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2" cols="12">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tableModifier.end_date"
                dense
                label="الى"
                outlined
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tableModifier.end_date" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="3" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="table.headers"
            loading-text="جاري التحميل ... الرجاء الانتظار"
            :items="table.Data"
            :options.sync="tableOptions"
            :server-items-length="table.totalData"
            :loading="table.loading"
            class="elevation-1"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }"
          >
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.customer_name="{ item }">
              <a @click="showBillsDetails(item.customer_id, item.customer_name, item.customer_mobile)">{{
                item.customer_name
              }}</a>
            </template>
            <template v-slot:item.price="{ item }">
              {{ numberWithComma(item.price) }}
            </template>
            <template v-slot:item.total_amount="{ item }">
              {{ numberWithComma(item.total_amount) }}
            </template>
            <template v-slot:item.payment_amount="{ item }">
              {{ numberWithComma(item.payment_amount) }}
            </template>
            <template v-slot:item.discount_amount="{ item }">
              {{ numberWithComma(item.discount_amount) }}
            </template>
            <template v-slot:item.remaining="{ item }">
              {{ numberWithComma(item.remaining) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FDD835" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="printPage(item)">
                    fa-print
                  </v-icon>
                </template>
                <span>طباعة</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="secondary"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="showBillsDetails(item.customer_id, item.customer_name, item.customer_mobile)"
                  >
                    fa-table
                  </v-icon>
                </template>
                <span>عرض الفواتير</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row no-gutters>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="addData.customer_id"
                    :rules="rules.required"
                    :loading="customers.loading"
                    :items="customers.data"
                    item-text="customer_name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="اختيار زبون"
                    @click:clear="addData.customer_id = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="addData.section_id"
                    :rules="rules.required"
                    :loading="sections.loading"
                    :items="sections.data"
                    item-text="name"
                    item-value="_id"
                    clearable
                    outlined
                    dense
                    label="اختيار قسم منتج"
                    @click:clear="addData.section_id = null"
                    @change="getAllServices()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="addData.service_selected"
                    :rules="rules.required"
                    :loading="services.loading"
                    :items="services.data"
                    item-text="name"
                    clearable
                    outlined
                    dense
                    label="نوع الخدمة"
                    return-object
                    @click:clear="addData.service_type = null"
                    @change="service_selected_changed()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-currency-field
                    v-model="addData.quantity"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="الكمية"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-currency-field
                    v-model="addData.price"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="المبلغ"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-currency-field
                    v-model="addData.discount_amount"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="الخصم"
                    outlined
                  />
                </v-col>
                <v-col cols="6">
                  <v-currency-field
                    v-model="addData.salaryPlusDiscountAmount"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="المبلغ الكلي بعد الخصم"
                    outlined
                    disabled
                  />
                </v-col>
                <v-col cols="6">
                  <v-currency-field
                    v-model="addData.payment_amount"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="المبلغ المدفوع"
                    outlined
                  />
                </v-col>
                <v-col cols="4">
                  <v-currency-field
                    v-model="addData.remainingAmount"
                    :error-messages="errors.rate"
                    :rules="rules.required"
                    dense
                    label="المبلغ المتبقي"
                    outlined
                    disabled
                  />
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="menuNextPayment"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.next_payment"
                        :disabled="nextPaymentDisable"
                        dense
                        label="تاريخ الدفعة القادمة"
                        outlined
                        clearable
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.next_payment" @input="menuNextPayment = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-menu
                    v-model="menuDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="addData.date"
                        dense
                        :rules="rules.date"
                        label="تاريخ انشاء الفاتورة"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.date" @input="menuDate = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="2" row-height="10" v-model="addData.desc" label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDialog.loading" :disabled="!addDialog.isFormValidAdd" @click="add">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add dialog -->
    <!--- Dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import customersApi from '@/api/customer'
import salaryOtherApi from '@/api/salary_other'
import sectionApi from '@/api/section_other'
import servicesApi from '@/api/service_other'
import { getCurrentDateInString } from '@/constant/date'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'
import { isAllowedToDelete } from '@/constant/isAllowed'

export default {
  data() {
    return {
      rate: 0,

      errors: {},

      account_type: null,

      menuStartDate: false,

      menuEndDate: false,

      icons: {
        mdiCashMultiple,
        mdiCash,
        mdiCashMultiple,
      },

      billsTypeSelectItems: [
        { text: 'الكل', value: null },
        { text: 'المدفوعة', value: true },
        { text: 'غير المدفوعة', value: false },
      ],

      isDeletedBillSelectItems: [
        { text: 'غير محذوفة', value: false },
        { text: 'محذوفة', value: true },
      ],

      tableModifier: {
        start_date: null,
        end_date: null,
      },

      rules: {
        required: [value => !!value || 'الحقل مطلوب'],
      },

      menuNextPayment: false,

      addData: {
        customer_id: null,
        price: null,
        payment_amount: null,
        discount_amount: null,
        remainingAmount: null,
        next_payment: null,
        desc: null,
        service_type: null,
        service_selected: {},
        section_id: null,
        salaryPlusDiscountAmount: null,
        quantity: 1,
        date: getCurrentDateInString(),
      },

      content_url: null,

      menuDate: false,

      editRules: {
        Rules: [
          value => !!value || 'الرمز مطلوب',
          value => (value && value.length > 5) || 'ستة احرف او ارفام على الاقل',
        ],
        ensureRules: [
          value => !!value || 'تاكيد الرمز مطلوب',
          value => (value && value.length > 5) || 'ستة احرف او ارفام على الاقل',
        ],
      },

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      xlsxData: {
        list: [],
        listLoading: true,
        downloadLoading: false,
        filename: 'الفواتير',
        autoWidth: true,
        bookType: 'xlsx',
      },

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      customers: {
        data: [],
        loading: false,
      },

      services: {
        data: [],
        loading: false,
      },

      sections: {
        data: [],
        loading: false,
      },

      nextPaymentDisable: false,

      otherData: {
        currencySymbol: ' IQD',
        payment_amount: 0,
        price: 0,
        discount_amount: 0,
      },

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
          },
          {
            text: 'اسم الزبون',
            sortable: false,
            value: 'customer_name',
          },
          {
            text: 'الهاتف',
            sortable: false,
            value: 'customer_mobile',
          },
          { text: 'المبلغ الكلي', sortable: false, value: 'total_amount' },
          { text: 'المبلغ المدفوع', sortable: false, value: 'payment_amount' },
          { text: 'الخصم', sortable: false, value: 'discount_amount' },
          { text: 'المتبقي', sortable: false, value: 'remaining' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },
  watch: {
    'tableModifier.billType': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.isDeleted': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.start_date': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },

    'tableModifier.end_date': {
      handler() {
        this.getDataAxios()
      },
      // deep: true,
    },

    'addData.price': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    'addData.payment_amount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },
    'addData.discount_amount': {
      handler() {
        this.salaryPlusDiscountAmountChange()
        this.paymentChange()
      },
      // deep: true,
    },

    'addData.remainingAmount': {
      handler() {
        if (this.addData.remainingAmount === 0) {
          this.nextPaymentDisable = true
          this.addData.next_payment = null
        } else {
          this.nextPaymentDisable = false
        }
      },
      // deep: true,
    },

    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
    '$vuetify.breakpoint': {
      handler() {
        if (this.$vuetify.breakpoint.xs) {
          this.isScreenXs = true
        } else {
          this.isScreenXs = false
        }
      },
      deep: true,
    },
  },

  created() {
    this.getAllCustomers()
    this.getAllSections()
    const account_type = this.$store.state.results.account_type

    this.account_type = account_type
  },

  methods: {
    showBillsDetails(id, name, phone) {
      this.$router.push(`/anotherBills/showBills/id/${id}/name/${name}/phone/${phone}`)
    },

    async getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = null
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await salaryOtherApi.get({
        start_date: this.tableModifier.start_date,
        end_date: this.tableModifier.end_date,
        page,
        limit: itemsPerPage,
        search,
      })
      this.otherData = response.data.results.otherData

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
      }
    },

    async getDataAxiosAll() {
      this.xlsxData.downloadLoading = true

      const response = await salaryOtherApi.getAll()

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.xlsxData.downloadLoading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.xlsxData.downloadLoading = false
        this.xlsxData.list = response.data.results
        this.handleDownload()
      }
    },

    salaryPlusDiscountAmountChange() {
      this.addData.salaryPlusDiscountAmount =
        (this.addData.price ? this.addData.price : 0) -
        (this.addData.discount_amount ? this.addData.discount_amount : 0)
      this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0
    },

    paymentChange() {
      this.addData.remainingAmount =
        (this.addData.salaryPlusDiscountAmount ? this.addData.salaryPlusDiscountAmount : 0) -
        (this.addData.payment_amount ? this.addData.payment_amount : 0)
      this.addData.remainingAmount ? this.addData.remainingAmount : 0
    },

    goToInvoicePage() {
      this.$router.push('invoice/4987')
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    async add() {
      this.addDialog.loading = true

      const response = await salaryOtherApi.add({
        customer_id: this.addData.customer_id,
        date: this.addData.date,
        service_type: this.addData.service_type,
        desc: this.addData.desc,
        mobile: this.addData.mobile,
        price: this.addData.price,
        discount_amount: this.addData.discount_amount,
        payment_amount: this.addData.payment_amount,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.message, 'primary')
        this.getDataAxios()
      }
    },

    async getAllCustomers() {
      this.customers.loading = true

      const response = await customersApi.getAll()

      if (response.status === 401) {
        this.customers.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.customers.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.customers.loading = false
        this.customers.data = response.data.results
      }
    },

    async getAllServices() {
      this.services.loading = true

      const response = await servicesApi.getAllBySection(this.addData.section_id)

      if (response.status === 401) {
        this.services.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.services.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.services.loading = false
        this.services.data = response.data.results
      }
    },

    async getAllSections() {
      this.sections.loading = true

      const response = await sectionApi.getAll()

      if (response.status === 401) {
        this.sections.loading = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.sections.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.sections.loading = false
        this.sections.data = response.data.results
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    handleDownload() {
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['اسم الزبون', 'هاتف الزبون', 'المبلغ الكلي', 'المبلغ المدفوع', 'الخصم', 'المتبقي', 'التاريخ']
        const filterVal = [
          'customer_name',
          'customer_mobile',
          'total_amount',
          'payment_amount',
          'discount_amount',
          'remaining',
          'date',
        ]

        // const { list } = this
        const data = this.formatJson(filterVal, this.xlsxData.list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.xlsxData.filename,
          autoWidth: this.xlsxData.autoWidth,
          bookType: this.xlsxData.bookType,
        })
        this.xlsxData.downloadLoading = false
      })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    printPage(item) {
      // let routeData = this.$router.resolve({ name: 'studentBillInvoice', params: { student_id: item.student_id, student_name: item.student_name, student_mobile: item.student_name, student_class: (item.account_division_current.class_name + ' - ' + item.account_division_current.leader) } });

      let routeData = this.$router.resolve({ name: 'anotherBillInvoice' })

      window.open(routeData.href, '_blank')

      localStorage.setItem('anotherBillInvoice', JSON.stringify(item))

      // router.push('studentBillInvoice')
    },

    service_selected_changed() {
      if (this.addData.service_selected) {
        this.addData.price = this.addData.service_selected.price
        this.addData.service_type = this.addData.service_selected._id
      } else {
        this.addData.price = null
        this.addData.service_type = null
      }
    },

    numberWithComma,

    isAllowedToDelete,
  },
}
</script>
