import axios from 'axios'

class salaryOtherApi {
  async get({ isDeleted, isPaid, start_date, end_date, page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(
      `counting/otherSalary/customerGrouped?search=${search}&isDeleted=${isDeleted}&isPaid=${isPaid}&start_date=${start_date}&end_date=${end_date}&page=${page}&limit=${limit}`,
    )
    return response
  }

  async getAll() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.get(`counting/otherSalary/customerGrouped/all`)
    return response
  }
}

export default new salaryOtherApi()
